/* .fc .fc-timegrid-slot {
    height: 1.5em;
    border-bottom: 0 !important;
} */

:root {
    --secondary-color: #D3348B;
    --primary-color: #E975AA;
    --tertiary-color: #8E68AA;
}
.publicHoliday {
    background-color: #f1f1f1!important;
}

.fc .fc-button {
    background-color: var(--primary-color);
    border-color: var(--secondary-color);
    box-shadow: none !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: var(--tertiary-color);
    border-color: var(--tertiary-color);
}

.fc .fc-button:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.fc .fc-button-primary:disabled {
    background-color: var(--tertiary-color);
    border-color: var(--tertiary-color);
}

.fc .fc-toolbar-title {
    color: var(--tertiary-color);
}